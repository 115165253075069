import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import regeneratorRuntime from "/home/jenkins/agent/workspace/web-sunrise-prod/web-sunrise-grandiaziende-deploy-prod/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { useEffect, useState } from "react";
import { usePageProvider } from "@vfit/consumer/providers";
import { APP, checkIsGoBackSIA, getDxlAppMock, isAppMock, setDxlCampaigns, useCreateAddress, useDXLCampaigns, useServiceabilityAddressMutation, useValidateAddress } from "@vfit/consumer/data-access";
import { ERROR_CODES_DYNAMIC_SHOPPING_CART, FLOW_TYPE_DYNAMIC_SHOPPING_CART } from "@vfit/shared/models";
import { getFromLocalStorageByKey } from "@vfit/shared/data-access";
import { DEFAULT_DYNAMIC_SHOPPING_CART_RESPONSE, DEFAULT_DYNAMIC_SHOPPING_CART_RESPONSE_ERROR, DEFAULT_DYNAMIC_SHOPPING_CART_RESPONSE_SUCCESS, retrieveCartInfo, getIAddConfiguration, iAddProductNotFound } from "./useDynamicShoppingCartFlow.utils";
export function useDynamicShoppingCartFlow() {
    var ref, ref1, ref2, ref3, ref4;
    var page = usePageProvider().page;
    var allProducts = (page === null || page === void 0 ? void 0 : (ref = page.params) === null || ref === void 0 ? void 0 : (ref1 = ref.elements) === null || ref1 === void 0 ? void 0 : ref1.productsAll) || [];
    var ref5 = useState({
        product: undefined,
        onConfirmAddress: false,
        alternativeProductList: []
    }), coverageToolFlowPayload = ref5[0], setCoverageToolFlowPayload = ref5[1];
    var ref6 = useState(DEFAULT_DYNAMIC_SHOPPING_CART_RESPONSE), dynamicShoppingCartResponse = ref6[0], setDynamicShoppingCartResponse = ref6[1];
    var ref7 = useValidateAddress(), validateAddressMutate = ref7.mutate, isLoadingValidateAddress = ref7.isLoading, isErrorValidateAddress = ref7.isError, isSuccessValidateAddress = ref7.isSuccess, validateAddressData = ref7.data;
    var ref8 = useCreateAddress(), createdAddressMutate = ref8.mutate, isLoadingCreatedAddress = ref8.isLoading, isErrorCreatedAddress = ref8.isError, isSuccessCreatedAddress = ref8.isSuccess;
    var ref9 = useServiceabilityAddressMutation((coverageToolFlowPayload === null || coverageToolFlowPayload === void 0 ? void 0 : (ref2 = coverageToolFlowPayload.product) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.offerId) === null || ref3 === void 0 ? void 0 : ref3.toString()) || "", allProducts || [], coverageToolFlowPayload.alternativeProductList, coverageToolFlowPayload === null || coverageToolFlowPayload === void 0 ? void 0 : (ref4 = coverageToolFlowPayload.product) === null || ref4 === void 0 ? void 0 : ref4.alternativeProductOfferListByTech), serviceAbilityMutate = ref9.mutate, isLoadingServiceAbility = ref9.isLoading, isErrorServiceAbility = ref9.isError, isSuccessServiceAbility = ref9.isSuccess;
    var ref10 = useDXLCampaigns(), isErrorDXLCampaigns = ref10.isError, isSuccessDXLCampaigns = ref10.isSuccess, isLoadingDXLCampaigns = ref10.isLoading, startDXLCampaigns = ref10.refetch;
    // region FLOWS
    var successFlow = function() {
        setDynamicShoppingCartResponse(_object_spread_props(_object_spread({}, DEFAULT_DYNAMIC_SHOPPING_CART_RESPONSE_SUCCESS), {
            flowType: (coverageToolFlowPayload === null || coverageToolFlowPayload === void 0 ? void 0 : coverageToolFlowPayload.flowType) || "",
            product: coverageToolFlowPayload === null || coverageToolFlowPayload === void 0 ? void 0 : coverageToolFlowPayload.product
        }));
    };
    var errorFlow = function(errorCode) {
        setDynamicShoppingCartResponse(_object_spread_props(_object_spread({}, DEFAULT_DYNAMIC_SHOPPING_CART_RESPONSE_ERROR), {
            flowType: (coverageToolFlowPayload === null || coverageToolFlowPayload === void 0 ? void 0 : coverageToolFlowPayload.flowType) || "",
            errorCode: errorCode
        }));
    };
    var initDynamicShoppingCart = function() {
        var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee() {
            var ref, ref1, cartInfo;
            return regeneratorRuntime.wrap(function _callee$(_ctx) {
                while(1)switch(_ctx.prev = _ctx.next){
                    case 0:
                        ;
                        _ctx.next = 3;
                        return retrieveCartInfo((page === null || page === void 0 ? void 0 : (ref = page.params) === null || ref === void 0 ? void 0 : (ref1 = ref.elements) === null || ref1 === void 0 ? void 0 : ref1.productsAll) || []);
                    case 3:
                        cartInfo = _ctx.sent;
                        if (!cartInfo) {
                            setDynamicShoppingCartResponse(_object_spread_props(_object_spread({}, DEFAULT_DYNAMIC_SHOPPING_CART_RESPONSE_SUCCESS), {
                                forceInit: true
                            }));
                        }
                        setCoverageToolFlowPayload(cartInfo);
                    case 6:
                    case "end":
                        return _ctx.stop();
                }
            }, _callee);
        }));
        return function initDynamicShoppingCart() {
            return _ref.apply(this, arguments);
        };
    }();
    var preconditionFlow = function() {
        switch(coverageToolFlowPayload.flowType){
            case FLOW_TYPE_DYNAMIC_SHOPPING_CART.SHOPPING_CART_FIXED:
                if ((coverageToolFlowPayload === null || coverageToolFlowPayload === void 0 ? void 0 : coverageToolFlowPayload.product) && (coverageToolFlowPayload === null || coverageToolFlowPayload === void 0 ? void 0 : coverageToolFlowPayload.onConfirmAddress)) {
                    var ref, ref1, ref2, ref3;
                    validateAddressMutate({
                        placeObject: _object_spread_props(_object_spread({}, coverageToolFlowPayload === null || coverageToolFlowPayload === void 0 ? void 0 : coverageToolFlowPayload.placeDetails), {
                            latitude: coverageToolFlowPayload === null || coverageToolFlowPayload === void 0 ? void 0 : (ref = coverageToolFlowPayload.placeDetails) === null || ref === void 0 ? void 0 : (ref1 = ref.latitude) === null || ref1 === void 0 ? void 0 : ref1.toString(),
                            longitude: coverageToolFlowPayload === null || coverageToolFlowPayload === void 0 ? void 0 : (ref2 = coverageToolFlowPayload.placeDetails) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.latitude) === null || ref3 === void 0 ? void 0 : ref3.toString()
                        })
                    });
                }
                break;
            case FLOW_TYPE_DYNAMIC_SHOPPING_CART.SHOPPING_CART_MOBILE:
                successFlow();
                break;
        }
    };
    var startFlow = function() {
        var ref;
        createdAddressMutate({
            address: validateAddressData === null || validateAddressData === void 0 ? void 0 : (ref = validateAddressData.validAddressList) === null || ref === void 0 ? void 0 : ref[0]
        });
    };
    // endregion
    useEffect(function() {
        if (isErrorDXLCampaigns) {
            var isApp = localStorage.getItem(APP);
            if (isApp) {
                errorFlow(ERROR_CODES_DYNAMIC_SHOPPING_CART.ERROR_DXL_CAMPAIGNS);
            }
        }
    }, [
        isErrorDXLCampaigns
    ]);
    useEffect(function() {
        if (isSuccessDXLCampaigns && !isLoadingDXLCampaigns) startFlow();
    }, [
        isSuccessDXLCampaigns,
        isLoadingDXLCampaigns
    ]);
    useEffect(function() {
        if (isSuccessServiceAbility) {
            var ref;
            var offeringServiceAbility = getFromLocalStorageByKey("offeringServiceability");
            if (!((ref = offeringServiceAbility) === null || ref === void 0 ? void 0 : ref.error)) {
                var isApp = localStorage.getItem(APP);
                if (isApp) {
                    var updatedIAddConfigAfterService = getIAddConfiguration(allProducts);
                    if (!updatedIAddConfigAfterService) {
                        // NO PRODUCT FOUND
                        errorFlow(ERROR_CODES_DYNAMIC_SHOPPING_CART.SERVICE_ABILITY_ERROR);
                        iAddProductNotFound();
                    } else {
                        setDynamicShoppingCartResponse(_object_spread_props(_object_spread({}, DEFAULT_DYNAMIC_SHOPPING_CART_RESPONSE_SUCCESS), {
                            flowType: (coverageToolFlowPayload === null || coverageToolFlowPayload === void 0 ? void 0 : coverageToolFlowPayload.flowType) || "",
                            product: updatedIAddConfigAfterService
                        }));
                    }
                } else {
                    successFlow();
                }
            } else {
                // NO PRODUCT FOUND
                errorFlow(ERROR_CODES_DYNAMIC_SHOPPING_CART.SERVICE_ABILITY_ERROR);
            }
        } else if (isErrorServiceAbility) {
            errorFlow(ERROR_CODES_DYNAMIC_SHOPPING_CART.SERVICE_ABILITY_ERROR);
        }
    }, [
        isSuccessServiceAbility
    ]);
    useEffect(function() {
        if (isSuccessCreatedAddress) {
            var ref;
            serviceAbilityMutate(validateAddressData === null || validateAddressData === void 0 ? void 0 : (ref = validateAddressData.validAddressList) === null || ref === void 0 ? void 0 : ref[0]);
        } else if (isErrorCreatedAddress) {
            errorFlow(ERROR_CODES_DYNAMIC_SHOPPING_CART.CREATED_ADDRESS_ERROR);
        }
    }, [
        isSuccessCreatedAddress
    ]);
    useEffect(function() {
        if (isSuccessValidateAddress) {
            var isApp = localStorage.getItem(APP);
            if (isApp) {
                if (isAppMock()) {
                    setDxlCampaigns(getDxlAppMock());
                    startFlow();
                } else {
                    startDXLCampaigns();
                }
            } else {
                startFlow();
            }
        } else if (isErrorValidateAddress) {
            errorFlow(ERROR_CODES_DYNAMIC_SHOPPING_CART.VALIDATE_ADDRESS_ERROR);
        }
    }, [
        isSuccessValidateAddress
    ]);
    useEffect(function() {
        if (isLoadingCreatedAddress || isLoadingServiceAbility || isLoadingValidateAddress || isLoadingValidateAddress) {
            setDynamicShoppingCartResponse(_object_spread_props(_object_spread({}, dynamicShoppingCartResponse), {
                isLoading: true
            }));
        }
    }, [
        isLoadingCreatedAddress,
        isLoadingServiceAbility,
        isLoadingValidateAddress,
        isLoadingValidateAddress, 
    ]);
    useEffect(function() {
        if (coverageToolFlowPayload.flowType) {
            setDynamicShoppingCartResponse(_object_spread_props(_object_spread({}, dynamicShoppingCartResponse), {
                flowType: (coverageToolFlowPayload === null || coverageToolFlowPayload === void 0 ? void 0 : coverageToolFlowPayload.flowType) || "",
                product: coverageToolFlowPayload === null || coverageToolFlowPayload === void 0 ? void 0 : coverageToolFlowPayload.product
            }));
            preconditionFlow();
        }
    }, [
        coverageToolFlowPayload.flowType
    ]);
    useEffect(function() {
        if (dynamicShoppingCartResponse === null || dynamicShoppingCartResponse === void 0 ? void 0 : dynamicShoppingCartResponse.isSuccess) {
            setTimeout(function() {
                if (!checkIsGoBackSIA()) window.history.pushState({}, document.title, window.location.pathname);
            }, 1000);
        }
    }, [
        dynamicShoppingCartResponse
    ]);
    useEffect(function() {
        if (page) initDynamicShoppingCart();
    }, [
        page
    ]);
    return dynamicShoppingCartResponse;
}
